export default {
    apiGateway: {
        REGION: "ap-southeast-2",
        URL: "https://41k74uvmxg.execute-api.ap-southeast-2.amazonaws.com/prod"
    },
    cognito: {
        REGION: "ap-southeast-2",
        USER_POOL_ID: "ap-southeast-2_79Qo4jDmd",
        APP_CLIENT_ID: "7nd6h41j081asbof10auhvl5ec",
        IDENTITY_POOL_ID: "ap-southeast-2:3ce323dc-5efa-4924-8437-0097a01a57ff"
    }
};