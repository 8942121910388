import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { API  } from "aws-amplify";
import { onError } from "../libs/errorLib";
import RaceCondition from '../components/RaceCondition';
import CountdownTimer from '../components/CountdownTimer';

export default function Race() {
    const { id } = useParams();
    const nav = useNavigate();
    const [raceData, setRaceData] = useState([]);
    const [raceExpired, setRaceExpired] = useState(false);

    useEffect(() => {
        function loadRace() {
            return API.get("races", `/race/${id}`);
        }

        async function onLoad() {
            try {
                const raceDetails = await loadRace();
                let raceData = JSON.parse(raceDetails.body);
                setRaceData(raceData)

            } catch (e) {
                console.log("in Error on Race page")
                onError(e);
            }
        }

        onLoad();
    }, [id]);

    function refreshPage() {
        setRaceExpired(true)
    }

    return (
        <div className={"container"}>
            {
                raceData.map((thisRace, index) => {
                    return (
                        <>
                            <h2>{thisRace.raceTrack}</h2>
                            <h3>Race: {thisRace.raceNumber} ({thisRace.raceState})</h3>
                            <CountdownTimer epoch_race_time={thisRace.raceTime}  refresh_main_page={refreshPage}/>
                            <RaceCondition key={index}
                                condition="No more than 3 runners paying under $2.00 a place"
                                condition_status={thisRace.MaxThreeRunnersUnderTwoDollars}
                                conditionData="-" />
                            <RaceCondition key={index}
                                condition="Favourite not in Box 1 and paying less than $1.04"
                                condition_status={thisRace.FavNotInBoxOneAndLessThanOneOhFive}
                                conditionData="-" />
                            <RaceCondition key={index}
                                condition="Trifecta paying more than $1500"
                                condition_status={thisRace.Trifecta.TrifectaOverOneThousand}
                                conditionData={thisRace.Trifecta.TrifectaAmount} />
                            <RaceCondition key={index}
                                condition="Has at least 7 runners"
                                condition_status={thisRace.Runners.HasSevenOrEightRunners}
                                conditionData={thisRace.Runners.runnersCount + " Runners"} />
                        </>
                    )
                })
            }
        </div>
    )
}