import React from 'react';
import { useCountdown } from './useCountdown';
import DateTimeDisplay from './DateTimeDisplay';
import "./CountdownTimer.css"

const ExpiredNotice = (refresh_main_page) => {
    return (
        <div className="expired-notice">
            <button onClick={refresh_main_page}>Reload</button>
            <span>Race Expired!!!</span>
        </div>
    );
};

const ShowCounter = ({ hours, minutes, seconds }) => {
    return (
        <div className="show-counter row">
            <DateTimeDisplay value={hours} type={'Hours'} isDanger={hours === 0 && minutes < 2 && seconds <= 59} />
            <p>:</p>
            <DateTimeDisplay value={minutes} type={'Mins'} isDanger={hours === 0 && minutes < 2 && seconds <= 59} />
            <p>:</p>
            <DateTimeDisplay value={seconds} type={'Seconds'} isDanger={ (hours === 0 && minutes < 2 && seconds <= 59) ||
                (hours === 0 && minutes < 0 && seconds <= -1)
            } />
        </div>
    );
};


const CountdownTimer = ({ epoch_race_time, refresh_main_page }) => {
    const [days, hours, minutes, seconds] = useCountdown(epoch_race_time);

    if (days + hours + minutes + seconds <= -60) {
        {refresh_main_page()};
        //return <ExpiredNotice refresh_main_page={refresh_main_page} />;
    } else {
        return (
            <ShowCounter
                hours={hours}
                minutes={minutes}
                seconds={seconds}
            />
        );
    }
};

export default CountdownTimer;