import React from 'react';
import "./RaceCondition.css"

export default function RaceCondition({
                                         condition,
                                         condition_status,
                                         conditionData
                                     }) {
    return (
        <div className={"row"}>
            <div className={"col-md-6 col-sm-6"}>{condition}</div>
            <div className={"col-md-3 col-sm-3"}><span className={condition_status}></span></div>
            <div className={"col-md-3 col-sm-3"}>{conditionData} </div>
        </div>
    )
}