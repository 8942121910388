import React from 'react';
import {Routes, Route} from 'react-router-dom';
import Home from './containers/Home';
import NotFound from './containers/NotFound';
import Login from './containers/Login';
import Race from './containers/Race';

export default function BettingRoutes() {
    return (
        <Routes>
            <Route exact path="/" element={<Home/>}></Route>
            <Route exact path="/login" element={<Login/>}></Route>
            <Route exact path="/race/:id" element={<Race/>}></Route>
            <Route element={<NotFound/>}></Route>
        </Routes>
    );
}