import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import "./RaceListItem.css"
import CountdownTimer from './CountdownTimer';

export default function RaceListItem({
                                         race_track_name,
                                         race_number,
                                         race_track_state,
                                         race_start_time,
                                         refresh_page

    }) {

    const hoursMinSecs = {hours:1, minutes: 20, seconds: 40}

    function convert_epoch_to_local(epoch) {
        var raceDate = new Date(epoch*1000);
        return raceDate.toLocaleString()
    }

    function refresh_main_page(){
        refresh_page();
    }

    return (
        <div className={"container"}>
            <div className={"row"}>
                <div className={"col"}>{race_track_name} ({race_number})<br />
                    <span className={"raceSubDetails"}>{race_track_state}</span>
                    <span className={"raceSubDetails"}>{convert_epoch_to_local(race_start_time)}</span>
                    <button onClick={refresh_main_page}>Reload</button>
                </div>
                <div className={"col"}><CountdownTimer epoch_race_time={race_start_time} refresh_main_page={refresh_main_page}/> </div>
            </div>
        </div>

    )
}