import React, { useState, useEffect } from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import { API } from "aws-amplify";
import { LinkContainer } from "react-router-bootstrap";
import "./Home.css";
import RaceListItem from '../components/RaceListItem';


export default function Home() {
    const [races, setRaces] = useState([]);
    const { isAuthenticated } = useAppContext();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function onLoad() {
            console.log("in load of Home page")
            if (!isAuthenticated) {
                return;
            }

            try {
                const notes = await loadNotes();
                setRaces(notes);
            } catch (e) {
                console.log("in Error on Home page")
                onError(e);
            }

            setIsLoading(false);
        }

        onLoad();
    }, [isAuthenticated]);

    function loadNotes() {
        return API.get("races", "/races");
    }

    function refreshPage() {
        window.location.reload(false);
    }

    function renderRaceList(races) {
        return [{}].concat(races).map((race, i) =>
            i !== 0 ? (
                <LinkContainer key={race.race_state + race.race_track_name + race.race_number} to={`/race/${race.race_id}`}>

                    <ListGroupItem>
                        <RaceListItem
                            race_track_name={race.race_track_name}
                            race_number={race.race_number}
                            race_start_time={race.race_time}
                            race_track_state={race.race_state}
                            refresh_page={refreshPage}
                        />
                    </ListGroupItem>
                </LinkContainer>
            ) : (
                <LinkContainer key="new" to="#">
                    <ListGroupItem>
                        <h4>
                            <b>Race List</b>
                        </h4>
                    </ListGroupItem>
                </LinkContainer>
            )
        );
    }

    function renderLander() {
        return (
            <div className="lander">
                <h1>Home</h1>
                <p>Betting App</p>
            </div>
        );
    }

    function renderRaces() {
        return (
            <div className="notes">
                <ListGroup>
                    {!isLoading && renderRaceList(races)}
                </ListGroup>
            </div>
        );
    }

    return (
        <div className="Home">
            {isAuthenticated ? renderRaces() : renderLander()}
        </div>
    );
}